// as found here: https://stackoverflow.com/a/72540683
AFRAME.registerComponent('move', {
    init: function() {
      // wait until the model is loaded
      this.el.addEventListener("model-loaded", evt => {
        const mixer = new THREE.AnimationMixer(this.el.components['gltf-model'].model);
        const clips = this.el.components['gltf-model'].model.animations[0];
        mixer.clipAction(clips).play();
        // "expose" the animation mixer
        this.mixer = mixer;
      })
    },
    // on each render loop (actually before each render loop)
    tick: function(t, dt) {
      if (!this.mixer) return; // if the mixer exists
      this.mixer.update(dt / 1000) // update it with the delta time
    }
  });